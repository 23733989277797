<p></p>

<section class='container pv-30'>
    <div class="row">
        <div class="col-12 text-center mb-5">
            <h2>{{cliente.razonSocial}}</h2>
        </div>
        <div class="col-12 col-md-6 text-center">
            <p><strong>E-Mail</strong></p>
            <p>{{cliente.eMail}}</p>
        </div>
        <div class="col-12 col-md-6 text-center">
            <p><strong>Descuento</strong></p>
            <p>{{cliente.descuentos}}</p>
        </div>
        <div class="col-12 col-md-6 text-center">
            <p><strong>Porcentaje Recargo Precio de Venta</strong></p>
            <p>
                <input name="recargoPrecioVenta" [(ngModel)]="cliente.recargoPrecioVenta" class="form-control max-with-40" type="number">
            </p>
        </div>
        <div class="col-12 col-md-6 text-center">
            <p><strong>Incluir IVA</strong></p>
            <p>
                <input name="incluirIva" type="checkbox" [(ngModel)]="cliente.incluirIva" class="form-check-input">
            </p>
        </div>
        <div class="col-12 text-center mb-5 mt-5 text-center">
            <button class="btn btn-dark w-25" (click)="onSave()"><fa-icon [icon]="faSave"></fa-icon> Guardar</button>
        </div>
    </div>


</section>