import { Injectable } from '@angular/core';
import { ComunicationService } from './comunication.service';
import { ArticulosBuscar } from '../Dtos/ArticulosBuscar';
import { Result } from '../Dtos/Result';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  constructor(
    private comunicationService: ComunicationService,
    private loginService: LoginService) { }

  public async Buscar(articulosBuscar: ArticulosBuscar): Promise<Result> {
    const ret: Result = { success: true, fields: []  };
    const parameters = this.comunicationService.objectToQueryString(articulosBuscar);

    try {
      await this.loginService.ifNecessaryRefreshToken();
      const resp = await this.comunicationService.httpGet('Articulos', 'buscar', parameters)
                                                 .toPromise();
      ret.response = resp;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        ret.success = false;
      }
    }

    return ret;
  }

}






// import { Injectable } from '@angular/core';
// import { ComunicationService } from './comunication.service';
// import { Comunicate } from '../Dtos/comunicate';
// import { HttpErrorResponse } from '@angular/common/http';
// import { Result } from '../Dtos/Result';

// @Injectable({
//   providedIn: 'root'
// })
// export class VariosService {
//   constructor(private comunicationService: ComunicationService) { }

//   public async Comunicate(comunicate: Comunicate): Promise<Result> {
//     const ret: Result = { success: true, fields: []  };

//     try {
//       await this.comunicationService.httpPost('Varios', 'comunicate', comunicate, false)
//                                     .toPromise();
//     } catch (error) {
//       if (error instanceof HttpErrorResponse) {
//         ret.success = false;

//         if (error.error.errors.Name !== undefined) {
//           ret.fields.push('name');
//         }

//         if (error.error.errors.Email !== undefined) {
//           ret.fields.push('email');
//         }

//         if (error.error.errors.Message !== undefined) {
//           ret.fields.push('message');
//         }
//       }
//     }

//     return ret;
//   }
// }
