import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SizeService {
  static IsXs = false;
  static IsSm = false;
  static IsMd = false;
  static IsLg = false;
  static IsXl = false;
  static Size: number;

  private isChanged = new Subject<boolean>();

  constructor() { }

  onStart(size) {
    this.verifySize(size);
  }

  onResize(event) {
    this.verifySize(event.target.innerWidth);
  }

  verifySize(size) {
    SizeService.Size = size;

    if (size >= 1600) {
      if (SizeService.IsXl === true) {
        return;
      }

      SizeService.IsXs = false;
      SizeService.IsSm = false;
      SizeService.IsMd = false;
      SizeService.IsLg = false;
      SizeService.IsXl = true;

    } else if (size >= 1200) {
      if (SizeService.IsLg === true) {
        return;
      }

      SizeService.IsXs = false;
      SizeService.IsSm = false;
      SizeService.IsMd = false;
      SizeService.IsLg = true;
      SizeService.IsXl = false;

    } else if (size >= 992) {
      if (SizeService.IsMd === true) {
        return;
      }

      SizeService.IsXs = false;
      SizeService.IsSm = false;
      SizeService.IsMd = true;
      SizeService.IsLg = false;
      SizeService.IsXl = false;

    } else if (size >= 768) {
      if (SizeService.IsSm === true) {
        return;
      }

      SizeService.IsXs = false;
      SizeService.IsSm = true;
      SizeService.IsMd = false;
      SizeService.IsLg = false;
      SizeService.IsXl = false;

    } else {
      if (SizeService.IsXs === true) {
        return;
      }

      SizeService.IsXs = true;
      SizeService.IsSm = false;
      SizeService.IsMd = false;
      SizeService.IsLg = false;
      SizeService.IsXl = false;
    }

    this.isChanged.next(true);
  }

  getIsChanged(): Observable<boolean> {
    return this.isChanged.asObservable();
  }
}
