import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AgmCoreModule } from '@agm/core';
import { FooterComponent } from './components/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './components/login/login.component';
import { ROUTES } from './app.routes';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { PagesComponent } from './components/pages/pages.component';
import { CarritoComprasComponent } from './components/carrito-compras/carrito-compras.component';
import { CuentasCorrientesComponent } from './components/cuentas-corrientes/cuentas-corrientes.component';
import { ArticulosService } from './services/articulos.service';
import { VariosService } from './services/varios.service';
import { SizeService } from 'src/app/services/size.service';
import { LoginService } from 'src/app/services/login.service';
import { CuentasCorrientesService } from './services/cuentas-corrientes.service';
import { ComunicationService } from './services/comunication.service';
import { CarritoComprasService } from './services/carritoCompras.service';
import { SessionState } from './state/session.state';
import { UserConfigComponent } from './components/user-config/user-config.component';
import { PicturesShowComponent } from './components/shared/pictures-show/pictures-show.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    FooterComponent,
    LoginComponent,
    ArticulosComponent,
    PagesComponent,
    CarritoComprasComponent,
    CuentasCorrientesComponent,
    UserConfigComponent,
    PicturesShowComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC7zbyccgc5y6qTyhBqRYg0Ro3YO0auj7Q'
    }),
    RouterModule.forRoot(ROUTES, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  providers: [
    ArticulosService,
    CarritoComprasService,
    ComunicationService,
    CuentasCorrientesService,
    LoginService,
    SizeService,
    VariosService,
    SessionState
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
