<body>
    <div class='sticky-top d-none d-lg-block m-0 p-0'>
        <app-nav-menu></app-nav-menu>
    </div>

    <div class='d-block d-lg-none'>
        <app-nav-menu></app-nav-menu>
    </div>

    <div class='container-fluid no-gutters px-0 h-min-50'>
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
    <app-login></app-login>
</body>