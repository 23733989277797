import { Injectable } from '@angular/core';
import { Login } from '../Dtos/Login';
import { LoginRefresh } from '../Dtos/LoginRefresh';
import { Result } from '../Dtos/Result';
import { ComunicationService } from './comunication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Session } from '../Dtos/session';
import { SessionState } from '../state/session.state';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private comunicationService: ComunicationService,
    private sessionState: SessionState,
    private router: Router) { }

  public async Login(login: Login): Promise<Result> {
    const ret: Result = { success: true, fields: []  };

    try {
      const reult = await this.comunicationService.httpPost('Login', 'authenticate', login, false)
                                                            .toPromise();
      const session: Session = {
        userName: reult.userName,
        token: reult.token,
        estadoId: reult.estadoId,
        expire: new Date(reult.expire).getTime(),
      };

      this.sessionState.Login(session);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        ret.success = false;

        if (error.error.errors !== undefined) {
          if (error.error.errors.User !== undefined) {
            ret.fields.push('user');
          }

          if (error.error.errors.Password !== undefined) {
            ret.fields.push('password');
          }
        } else {
          ret.message = error.error.message;
        }
      }
    }

    return ret;
  }

  public async ifNecessaryRefreshToken() {
    const session = this.sessionState.Get();

    if (session.expire > Date.now()) {
      return;
    }

    const ok = await this.refreshToken(session.token);
    if (!ok) {
      this.sessionState.Logout();
      this.router.navigate(['/']);
    }
  }

  private async refreshToken(token: string): Promise<boolean> {
    let ret = false;

    try {
      const refresh: LoginRefresh = {
        refreshToken: token
      };

      const reult = await this.comunicationService.httpPost('Login', 'refresh', refresh, false).toPromise();
      // const reult = await this.comunicationService.httpGet('Login', 'refresh', '', true).toPromise<any>();

      const session: Session = {
        userName: reult.userName,
        token: reult.token,
        estadoId: reult.estadoId,
        expire: new Date(reult.expire).getTime(),
      };

      this.sessionState.Login(session);

      ret = true;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {

      }
    }

    return ret;
  }

  public async forceRefreshToken() {
    const session = this.sessionState.Get();
    const ok = await this.refreshToken(session.token);
    if (!ok) {
      this.sessionState.Logout();
      this.router.navigate(['/']);
    }
  }
}
