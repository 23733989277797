<section class='container-fluid py-5'>
    <div class="d-none d-lg-block px-3 px-xl-5">
        <table class="table table-hover table-colored table-borderless">
            <thead>
                <th class='text-center align-middle'>Fecha</th>
                <th class='text-center align-middle'>Articulo</th>
                <th class='text-center align-middle'>Marca</th>
                <th class='text-center align-middle'>Cantidad</th>
                <th class='text-center align-middle'>
                    <p class='mb-0'>Precio</p>
                    <p class='mb-0'>Lista</p>
                </th>
                <th class='text-center'>
                    <p class='mb-0'>Precio</p>
                    <p class='mb-0'>Neto</p>
                </th>
                <th class='text-center align-middle'>Sub Total</th>
                <th></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of carritoComprasList; let i = index">
                    <tr *ngIf="!item.texto" class="bb-colored">
                        <td>{{item.fecha | date:'dd/mm/yyyy HH:ss'}}</td>
                        <td>{{item.cogigo}}</td>
                        <td>{{item.procedencia}}</td>
                        <td class='text-right'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;">
                                <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)">
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td class='text-right'>{{item.moneda}} {{item.precioLista | number: '.2-2'}}.-</td>
                        <td class='text-right'>{{item.moneda}} {{item.precioNeto | number: '.2-2'}}.-</td>
                        <td class='text-right'>{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</td>
                        <td>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                          <fa-icon [icon]="faTrash"></fa-icon>
                      </button>
                        </td>
                    </tr>

                    <tr *ngIf="item.texto">
                        <td class='pb-0'>{{item.fecha | date:'dd/mm/yyyy HH:ss'}}</td>
                        <td class='pb-0'>{{item.cogigo}}</td>
                        <td class='pb-0'>{{item.procedencia}}</td>
                        <td class='text-right pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;" *ngIf="!isEnviandoPedido">
                                <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)" *ngIf="!isEnviandoPedido">
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td class='text-right pb-0'>{{item.moneda}} {{item.precioLista | number: '.2-2'}}.-</td>
                        <td class='text-right pb-0'>{{item.moneda}} {{item.precioNeto | number: '.2-2'}}.-</td>
                        <td class='text-right pb-0'>{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</td>
                        <td class='pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                          <fa-icon [icon]="faTrash"></fa-icon>
                      </button>
                        </td>
                    </tr>
                    <tr *ngIf="item.texto" class='bb-colored pt-0'>
                        <td colspan="7">{{item.texto}}</td>
                    </tr>
                </ng-container>

                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2" class='text-right bb-colored background-default background-default-color'>Total Pedido</td>
                    <td colspan="2" class='text-right bb-colored background-default background-default-color'><strong>$ {{totalPedido | number: '.2-2'}}.-</strong></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-none d-md-block d-lg-none">
        <table class="table table-hover table-colored table-borderless">
            <thead>
                <th class='text-center align-middle'>Fecha</th>
                <th class='text-center align-middle'>Articulo</th>
                <th class='text-center align-middle'>Cantidad</th>
                <th class='text-center align-middle'>
                    <p class='mb-0'>Precio</p>
                    <p class='mb-0'>Lista</p>
                </th>
                <th class='text-center'>
                    <p class='mb-0'>Precio</p>
                    <p class='mb-0'>Neto</p>
                </th>
                <th class='text-center'>
                    <p class='mb-0'>Sub</p>
                    <p class='mb-0'>Total</p>
                </th>
                <th></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of carritoComprasList; let i = index">
                    <tr *ngIf="!item.texto" class="bb-colored">
                        <td>{{item.fecha | date:'dd/mm/yyyy'}}</td>
                        <td>
                            <p class="mb-0">{{item.cogigo}}</p>
                            <p class="mb-0">{{item.procedencia}}</p>
                        </td>
                        <td class='text-right'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;">
                                <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)">
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td class='text-right'>{{item.moneda}} {{item.precioLista | number: '.2-2'}}.-</td>
                        <td class='text-right'>{{item.moneda}} {{item.precioNeto | number: '.2-2'}}.-</td>
                        <td class='text-right'>{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</td>
                        <td>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                        </td>
                    </tr>

                    <tr *ngIf="item.texto">
                        <td class='pb-0'>{{item.fecha | date:'dd/mm/yyyy'}}</td>
                        <td>
                            <p class="mb-0">{{item.cogigo}}</p>
                            <p class="mb-0">{{item.procedencia}}</p>
                        </td>
                        <td class='text-right pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;" *ngIf="!isEnviandoPedido">
                                <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)" *ngIf="!isEnviandoPedido">
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td class='text-right pb-0'>{{item.moneda}} {{item.precioLista | number: '.2-2'}}.-</td>
                        <td class='text-right pb-0'>{{item.moneda}} {{item.precioNeto | number: '.2-2'}}.-</td>
                        <td class='text-right pb-0'>{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</td>
                        <td class='pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="item.texto" class='bb-colored pt-0'>
                        <td colspan="7">{{item.texto}}</td>
                    </tr>
                </ng-container>

                <tr>
                    <td></td>
                    <td></td>
                    <td colspan="2" class='text-right bb-colored background-default background-default-color'>Total Pedido</td>
                    <td colspan="2" class='text-right bb-colored background-default background-default-color'><strong>$ {{totalPedido | number: '.2-2'}}.-</strong></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-none d-sm-block d-md-none">
        <table class="table table-hover table-colored table-borderless">
            <thead>
                <th class='text-center align-middle'>Articulo</th>
                <th class='text-center align-middle'>Cantidad</th>
                <th class='text-center'>
                    <p class='mb-0'>Sub</p>
                    <p class='mb-0'>Total</p>
                </th>
                <th></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of carritoComprasList; let i = index">
                    <tr *ngIf="!item.texto" class="bb-colored">
                        <td>
                            <p class="mb-0">{{item.cogigo}}</p>
                            <p class="mb-0">{{item.procedencia}}</p>
                        </td>
                        <td class='text-right'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;">
                              <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)">
                              <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td class='text-right'>{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</td>
                        <td>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                              <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </td>
                    </tr>

                    <tr *ngIf="item.texto">
                        <td>
                            <p class="mb-0">{{item.cogigo}}</p>
                            <p class="mb-0">{{item.procedencia}}</p>
                        </td>
                        <td class='text-right pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;" *ngIf="!isEnviandoPedido">
                              <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)" *ngIf="!isEnviandoPedido">
                              <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td class='text-right pb-0'>{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</td>
                        <td class='pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                              <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </td>
                    </tr>
                    <tr *ngIf="item.texto" class='bb-colored pt-0'>
                        <td colspan="4">{{item.texto}}</td>
                    </tr>
                </ng-container>

                <tr>
                    <td colspan="2" class='text-right bb-colored background-default background-default-color'>Total Pedido</td>
                    <td colspan="2" class='text-right bb-colored background-default background-default-color'><strong>$ {{totalPedido | number: '.2-2'}}.-</strong></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-block d-sm-none">
        <table class="table table-hover table-colored table-borderless">
            <tbody>
                <ng-container *ngFor="let item of carritoComprasList; let i = index">
                    <tr *ngIf="!item.texto" class="bb-colored">
                        <td>
                            <p class="mb-0">{{item.cogigo}}</p>
                            <p class="mb-0">{{item.procedencia}}</p>
                        </td>
                        <td class='text-right'>

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;">
                              <fa-icon [icon]="faMinusCircle"></fa-icon>
                            </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)">
                              <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>

                            <p class="mb-0 text-right">{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</p>
                        </td>
                        <td>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                        </td>
                    </tr>

                    <tr *ngIf="item.texto">
                        <td>
                            <p class="mb-0">{{item.cogigo}}</p>
                            <p class="mb-0">{{item.procedencia}}</p>
                        </td>
                        <td class='text-right pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="minusClick(i)" style="float: left;" *ngIf="!isEnviandoPedido">
                            <fa-icon [icon]="faMinusCircle"></fa-icon>
                          </button> {{item.cantidad}}

                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow" (click)="plusClick(i)" *ngIf="!isEnviandoPedido">
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                          </button>
                            <p class="mb-0 text-right">{{item.moneda}} {{item.cantidad * item.precioNeto | number: '.2-2'}}.-</p>
                        </td>
                        <td class='pb-0'>
                            <button type="button" class="btn color-opposite pt-0 pb-0 focus-no-shadow float-right" (click)="showBorrar(borrarModal, i)" focusable='false'>
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        </td>
                    </tr>
                    <tr *ngIf="item.texto" class='bb-colored pt-0'>
                        <td colspan="3">{{item.texto}}</td>
                    </tr>
                </ng-container>

                <tr>
                    <td colspan="3" class='text-right bb-colored background-default background-default-color'>
                        Total Pedido
                        <strong>$ {{totalPedido | number: '.2-2'}}.-</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="input-group py-4 px-0 px-lg-3 px-xl-5">
        <div class="input-group-prepend">
            <div class="input-group-text background-default text-white">
                Observaciones
            </div>
        </div>
        <input [(ngModel)]="obsernaciones" type="text" class="form-control" placeholder="Observaciones" *ngIf="!isEnviandoPedido">
    </div>

    <div class='pt-3 pb-5 px-0 px-lg-3 px-xl-5'>
        <button type="button" class="btn background-default background-default-color" (click)="enviarPedido()" style="float: right;" *ngIf="!isEnviandoPedido">
            Enviar Pedido <fa-icon [icon]="faPaperPlane"></fa-icon>
        </button>
    </div>
</section>


<ng-template #borrarModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="loginModalLabel">Eliminar</h5>
        <button type="button" #pedirClosebutton class="btn btn-transparent btnNoFocus" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><fa-icon [icon]="faTimes"></fa-icon></span>
        </button>
    </div>

    <div class="modal-body">
        <div class='row'>
            <div class="col-12">
                ¿Está seguro que desea eliminar el articulo <strong>{{carritoComprasListCurrent.cogigo}} {{carritoComprasListCurrent.procedencia}}</strong> del pedido?
            </div>

            <div class="col-8 offset-2 pt-5 pb-4">
                <button type="button" class="btn background-default background-default-color" (click)="borrarButtonClick(1)">
                    Si
                </button>

                <button type="button" class="btn background-opposite background-opposite-color float-right" (click)="borrarButtonClick(0)">
                    No
                </button>
            </div>
        </div>
    </div>
</ng-template>