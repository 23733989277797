import { Injectable } from '@angular/core';
import { ComunicationService } from './comunication.service';
import { Comunicate } from '../Dtos/comunicate';
import { HttpErrorResponse } from '@angular/common/http';
import { Result } from '../Dtos/Result';

@Injectable({
  providedIn: 'root'
})
export class VariosService {
  constructor(private comunicationService: ComunicationService) { }

  public async Comunicate(comunicate: Comunicate): Promise<Result> {
    const ret: Result = { success: true, fields: []  };

    try {
      await this.comunicationService.httpPost('Varios', 'comunicate', comunicate, false)
                                    .toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        ret.success = false;

        if (error.error.errors.Name !== undefined) {
          ret.fields.push('name');
        }

        if (error.error.errors.Email !== undefined) {
          ret.fields.push('email');
        }

        if (error.error.errors.Message !== undefined) {
          ret.fields.push('message');
        }
      }
    }

    return ret;
  }
}
