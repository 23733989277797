<footer class='container-fluid'>
    <div class="container">
        <div class="row pt-5">
            <div class="col-12 col-xl-8 offset-xl-2 text-center mb-3">
                <div class="d-block d-md-none">
                    <img src="../../../assets/img/logos/logo_big.png" class='img-fluid'>
                </div>
                <div class="d-none d-md-block">
                    <img src="../../../assets/img/logos/logo_big.png" class='img-fluid'>
                </div>
            </div>

            <div class="col-12 col-md-4 text-center">
                <p class="color-default">
                    <fa-icon [icon]="faMap"></fa-icon> Direccion
                </p>
                <p><span class="ml-2 titulo">F, Félix San Martín 1328, 8300 Neuquén</span></p>

            </div>

            <div class="col-12 col-md-4 text-center">
                <p class="color-default">
                    <fa-icon [icon]="faEnvelope"></fa-icon> E-mail
                </p>
                <p><a href="mailto:admin@rodamientossursrl.com.ar" class="ml-2 titulo">admin@rodamientossursrl.com.ar</a></p>
            </div>

            <div class="col-12 col-md-4 text-center">
                <p class="color-default">
                    <fa-icon [icon]="faPhone"></fa-icon> Telefono
                </p>
                <p><span class="ml-2 titulo">+54 299 443-5404</span></p>
            </div>

            <div class="col-12 col-md-4 text-center">
                <p class="color-default">
                    <fa-icon [icon]="faWhatsapp" class="color-default icon-size"></fa-icon> Whatsapp
                </p>
                <p>
                    <a href="https://wa.me/5492994571328" target="_blank">
                        299-4571328
                    </a>
                </p>
            </div>

            <div class="col-12 col-md-4 text-center">
                <p class="color-default">
                    <fa-icon [icon]="faFacebook" class="color-default icon-size"></fa-icon> Facebook
                </p>
                <p>
                    <a href="https://es-la.facebook.com/people/Rodamientos-Sur-Srl/100006540887590" target="_blank">
                        Rodamientos Sur srl
                    </a>
                </p>
            </div>

            <div class="col-12 col-md-4 text-center">
                <p class="color-default">
                    <fa-icon [icon]="faInstagram" class="color-default icon-size"></fa-icon> Instagram
                </p>
                <p>
                    <a href="https://www.instagram.com/rodamientossur/" target="_blank">
                        Rodamientos Sur srl
                    </a>
                </p>
            </div>

        </div>

        <div class="separator my-3"></div>

        <div class="text-center pb-5">
            &copy; 2020 Rodamientos Sur Desarrollado por <a target="_blank" href="http://www.vlsystemas.com">VL Systemas</a>
        </div>
    </div>
</footer>