import { Injectable } from '@angular/core';
import { ComunicationService } from './comunication.service';
import { CarritoComprasAdd } from '../Dtos/CarritoComprasAdd';
import { Result } from '../Dtos/Result';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from './login.service';
import { SessionState } from '../state/session.state';
import { CarritoComprasList } from '../Dtos/CarritoComprasList';
import { promise } from 'protractor';
import { CarritoComprasPedidoDto } from '../Dtos/carritoComprasPedidoDto';

@Injectable({
  providedIn: 'root'
})
export class CarritoComprasService {

  constructor(
    private comunicationService: ComunicationService,
    private sessionState: SessionState,
    private loginService: LoginService) { }

  public async add(carritoComprasAdd: CarritoComprasAdd): Promise<Result> {
    const ret: Result = { success: true, fields: []  };

    try {
      await this.loginService.ifNecessaryRefreshToken();

      const resp = await this.comunicationService.httpPost('CarritoCompras', '', carritoComprasAdd).toPromise();
      ret.response = resp;

      this.sessionState.EnCarrito(resp);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        ret.success = false;
      }
    }

    return ret;
  }

  public async count(): Promise<number> {
    let ret = 0;

    try {
      await this.loginService.ifNecessaryRefreshToken();
      ret = await this.comunicationService.httpGet('CarritoCompras', 'count').toPromise();

      this.sessionState.EnCarrito(ret);
    } catch (error) {
      console.log('A - ', error);
    }

    return ret;
  }

  public async listado(): Promise<CarritoComprasList[]> {
    let ret: CarritoComprasList[];

    try {
      await this.loginService.ifNecessaryRefreshToken();

      ret = await this.comunicationService.httpGet('CarritoCompras').toPromise();
    } catch (error) {
      console.log('B - ', error);
    }

    return ret;
  }

  public async delete(id: Number) {
    try {
      await this.loginService.ifNecessaryRefreshToken();
      await this.comunicationService.httpDelete('CarritoCompras', '', 'id=' + id).toPromise();
    } catch (error) {
      console.log('C - ', error);
    }
  }

  public async enviar(pedido: CarritoComprasPedidoDto): Promise<boolean> {
    let ret = false;

    try {
      await this.loginService.ifNecessaryRefreshToken();
      await this.comunicationService.httpPost('CarritoCompras', 'pedir', pedido).toPromise();

      ret = true;
    } catch (error) {
      console.log('B - ', error);
    }

    return ret;
  }
}

