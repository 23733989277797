<section>
    <div class="d-none d-lg-block">
        <div id="carouselLgControls" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselLgIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselLgIndicators" data-slide-to="1"></li>
                <li data-target="#carouselLgIndicators" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner sliderHome">
                <div class="carousel-item active">
                    <img src="../../assets/img/home/Lg/slider01.jpg" class="img-fluid w-100">
                </div>
                <div class="carousel-item">
                    <img src="../../assets/img/home/Lg/slider02.jpg" class="img-fluid w-100">
                </div>
                <div class="carousel-item">
                    <img src="../../assets/img/home/Lg/slider03.jpg" class="img-fluid w-100">
                </div>
            </div>

            <a class="carousel-control-prev" href="#carouselLgControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselLgControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

    <div class="d-none d-sm-block d-lg-none">
        <div id="carouselMdControls" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselMdIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselMdIndicators" data-slide-to="1"></li>
                <li data-target="#carouselMdIndicators" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner sliderHome">
                <div class="carousel-item active">
                    <img src="../../assets/img/home/Md/slider01.jpg" class="img-fluid w-100">
                </div>
                <div class="carousel-item">
                    <img src="../../assets/img/home/Md/slider02.jpg" class="img-fluid w-100">
                </div>
                <div class="carousel-item">
                    <img src="../../assets/img/home/Md/slider03.jpg" class="img-fluid w-100">
                </div>
            </div>

            <a class="carousel-control-prev" href="#carouselMdControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselMdControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

    <div class="d-block d-sm-none">
        <div id="carouselXsControls" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselXsIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselXsIndicators" data-slide-to="1"></li>
                <li data-target="#carouselXsIndicators" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner sliderHome">
                <div class="carousel-item active">
                    <img src="../../assets/img/home/Xs/slider01.jpg" class="img-fluid w-100">
                </div>
                <div class="carousel-item">
                    <img src="../../assets/img/home/Xs/slider02.jpg" class="img-fluid w-100">
                </div>
                <div class="carousel-item">
                    <img src="../../assets/img/home/Xs/slider03.jpg" class="img-fluid w-100">
                </div>
            </div>

            <a class="carousel-control-prev" href="#carouselXsControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselXsControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</section>

<div id="quienesSomos" class="parallax parallax-1"></div>

<section class='container py-3 py-md-5'>
    <h1 class='underline-red'>Quienes somos</h1>

    <div class='row pt-3'>
        <div class="col-12 col-md-4 col-lg-3 mt-2">
            <img src="../../assets/img/home/negocio.jpg" class='rounded img-fluid'>
        </div>

        <div class="col-12 col-md-8 col-lg-9 mt-2">
            <p><strong>Rodamientos Sur srl</strong> es una empresa fundada hace más de 30 años por <strong>Julio Oscar Migliavaca</strong>, convirtiéndose en un referente en la zona.</p>
            <p>La empresa se orienta al mercado de venta, distribución y reposición de rodamientos y autopartes, tanto automotriz como industrial.</p>
            <p>Nuestra zona de representación abarca las provincias de Neuquén, Rio Negro, La Pampa y Chubut.</p>
            <p>La atención personalizada hace que nuestros clientes nos elijan por nuestro servicio de venta, post- venta y logística.</p>
            <p>A lo largo de nuestra historia nos hemos esforzado en mantener en nuestra actividad la honestidad, la dedicación, la confianza y el estándar de calidad esperado….</p>
        </div>
    </div>
</section>

<div id="industrias" class="parallax parallax-2"></div>

<section class='container py-5'>
    <h1 class='underline-red'>Industrias</h1>

    <div class="card-deck pt-3">
        <div class='row'>
            <div class="col-12 col-sm-6 col-lg-4">
                <div class="card h-100">
                    <div class="card-header card-header-colored text-center">
                        <h5>Automotriz</h5>
                    </div>
                    <div class="card-body">
                        <p class="card-text">Cada cliente tiene sus propios desafíos que requieren de diferentes soluciones</p>
                        <p class="card-text">Nos dedicamos a todo lo que implica:</p>
                        <ul>
                            <li>Distribución</li>
                            <li>Bombas de agua</li>
                            <li>Correas</li>
                            <li>Mazas de rueda</li>
                            <li>Suspensión</li>
                            <li>Kit de embragues</li>
                            <li>Craposinas</li>
                        </ul>
                        <p class="card-text">La carpeta de productos es tan amplia como vehículos en el mercado.</p>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-6 col-lg-4">
                <div class="card h-100">
                    <div class="card-header card-header-colored text-center">
                        <h5>Petrolera</h5>
                    </div>
                    <div class="card-body">
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 mt-sm-3 mt-lg-0">
                <div class="card h-100">
                    <div class="card-header card-header-colored text-center">
                        <h5>Frutícola</h5>
                    </div>
                    <div class="card-body">
                        <p class="card-text">En la zona donde nos encontramos se desarrolla todo lo que tiene que ver con la producción de frutas (manzanas, pera, frutos rojos y demás). Donde Asistimos a los mismos con los requerimientos para su desarrollo.</p>
                        <p class="card-text">Como también la zona ha dado el fruto de la uva generando la industria vitivinícola. A la cual también asistimos a distintas bodegas.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="marcas" class="parallax parallax-3"></div>

<section class='container py-5'>
    <h1 class='underline-red'>Marcas</h1>

    <div class='row pt-3'>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/armetal.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/baztarrica.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/dbh.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/frasle.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/imbasa.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/ina.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/koyo.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/nakata.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/ntn.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/perfect_circle.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/plasbestos.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/raybestos.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/skf.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/snr.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/timken.png" class="img-fluid"> &nbsp;
        </div>
        <div class="col-6 col-md-4 col-lg-3">
            <img src="../../assets/img/marcas/trezeguet.png" class="img-fluid"> &nbsp;
        </div>
    </div>


</section>

<div id="productos" class="parallax parallax-4"></div>

<section class='container py-5'>
    <h1 class='underline-red'>Productos</h1>

    <div class='row pt-3'>
        <div class="col-12 col-md-6 col-lg-4 from-left pt-3" *ngFor="let product of productosText">
            <div class="icon card-header-colored circle">
                <fa-icon [icon]="faCheck"></fa-icon>
            </div>
            <span class="titulo">{{product}}</span>
        </div>
    </div>
</section>

<div id="contactenos" class="parallax parallax-5"></div>

<section class='container py-5'>
    <h1 class='underline-red'>Contactenos</h1>

    <div class='row pt-3'>
        <div class="col-12 col-md-6">
            <p>
                <fa-icon [icon]="faMap" class="color-default"></fa-icon>
                <span class="ml-2 titulo">F, Félix San Martín 1328, 8300 Neuquén</span>
            </p>

            <table class="w-100 mb-3">
                <tr>
                    <td class='align-top pt-1'>
                        <fa-icon [icon]="faEnvelope" class="color-default"></fa-icon>
                    </td>
                    <td>
                        <p class="mb-0"><a href="mailto:administracion@rodamientossursrl.com.ar" class="titulo">administracion@rodamientossursrl.com.ar</a></p>
                        <p class="mb-0"><a href="mailto:consultas@rodamientossursrl.com.ar" class="titulo">consultas@rodamientossursrl.com.ar</a></p>
                        <p class="mb-0"><a href="mailto:ventas@rodamientossursrl.com.ar" class="titulo">ventas@rodamientossursrl.com.ar</a></p>
                    </td>
                </tr>
            </table>

            <p>
                <fa-icon [icon]="faPhone" class="color-default"></fa-icon>
                <span class="ml-2 titulo">+54 299 443-5404</span>
            </p>
            <p>
                <a href="https://wa.me/5492994571328" target="_blank">
                    <fa-icon [icon]="faWhatsapp" class="color-default icon-size"></fa-icon>
                    <span class="ml-2 titulo">299-4571328</span>
                </a>
            </p>
            <p>
                <a href="https://es-la.facebook.com/people/Rodamientos-Sur-Srl/100006540887590" target="_blank">
                    <fa-icon [icon]="faFacebook" class="color-default icon-size"></fa-icon>
                    <span class="ml-2 titulo">Rodamientos Sur srl</span>
                </a>
            </p>
            <p>
                <a href="https://www.instagram.com/rodamientossur/" target="_blank">
                    <fa-icon [icon]="faInstagram" class="color-default icon-size"></fa-icon>
                    <span class="ml-2 titulo">Rodamientos Sur srl</span>
                </a>
            </p>
        </div>

        <form class="col-12 col-md-6 row" #comunicateForm="ngForm" (ngSubmit)="comunicateClick(comunicateForm)">
            <div class="form-group col-12 col-md-6">
                <input name='name' ngModel type="text" class="form-control" placeholder="Nombre">
            </div>

            <div class="form-group col-12 col-md-6">
                <input name='email' ngModel type="email" class="form-control" placeholder="Email">
            </div>

            <div class="form-group col-12">
                <textarea name='message' ngModel class="form-control" rows="10" placeholder="Mensaje"></textarea>
            </div>

            <div class="form-group col-12 text-right">
                <button id='comunicateButton' type="submit" class="btn background-default background-default-color mb-2">
                    <fa-icon [icon]="faPaperPlane"></fa-icon>&nbsp;&nbsp;
                    Enviar
                </button>
            </div>
        </form>
    </div>
</section>

<div class="mapouter mt-5">
    <div class="gmap_canvas">
        <iframe width="100%" height="500px" id="gmap_canvas" src="https://maps.google.com/maps?ll=-38.959264,-68.040644&q='Rodamientos Sur SRL'&t=&z=16&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </div>
    <style>
        .mapouter {
            position: relative;
            text-align: right;
            height: 500px;
            width: 100%;
        }
        
        .gmap_canvas {
            overflow: hidden;
            background: none!important;
            height: 500px;
            width: 100%;
        }
    </style>
</div>