import { Component, OnInit } from '@angular/core';
import { SizeService } from './services/size.service';
import { SessionState } from './state/session.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private sizeService: SizeService,
    private sessionState: SessionState ) {
    this.sessionState.RefreshSession();
  }

  ngOnInit() {
    this.sizeService.onStart(window.innerWidth);
  }

  onResize(event) {
    this.sizeService.onResize(event);
  }
}
