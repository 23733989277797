import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { CarritoComprasComponent } from './components/carrito-compras/carrito-compras.component';
import { CuentasCorrientesComponent } from './components/cuentas-corrientes/cuentas-corrientes.component';
import { UserConfigComponent } from './components/user-config/user-config.component';

export const ROUTES: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'articulos', component: ArticulosComponent },
    { path: 'carritocompras', component: CarritoComprasComponent },
    { path: 'cuentascorrientes', component: CuentasCorrientesComponent },
    { path: 'config', component: UserConfigComponent }
];
