import { Component, OnInit } from '@angular/core';
import { faPlusCircle, faMinusCircle, faTrash, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { CarritoComprasService } from '../../services/carritoCompras.service';

import { CarritoComprasList } from 'src/app/Dtos/CarritoComprasList';
import { CarritoComprasPedidoDto } from '../../Dtos/carritoComprasPedidoDto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-carrito-compras',
  templateUrl: './carrito-compras.component.html',
  styleUrls: ['./carrito-compras.component.css']
})
export class CarritoComprasComponent implements OnInit {
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faPaperPlane = faPaperPlane;
  faTrash = faTrash;
  faTimes = faTimes;

  obsernaciones: string;

  carritoComprasList: CarritoComprasList[];
  totalPedido: number;

  isEnviandoPedido = false;

  carritoComprasListCurrent: CarritoComprasList;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private carritoComprasService: CarritoComprasService) { }

  async ngOnInit() {
    this.carritoComprasList = await this.carritoComprasService.listado();
    await this.actializarTotal();
  }

  async minusClick(idx: number) {
    if (this.carritoComprasList[idx].cantidad > this.carritoComprasList[idx].cantidadMinima) {
      this.carritoComprasList[idx].cantidad = this.carritoComprasList[idx].cantidad - 1;
      await this.actializarTotal();
    }
  }

  async plusClick(idx: number) {
    this.carritoComprasList[idx].cantidad = this.carritoComprasList[idx].cantidad + 1;
    await this.actializarTotal();
  }

  async showBorrar(modal, idx: number) {
    this.carritoComprasListCurrent = this.carritoComprasList[idx];
    this.modalService.open(modal, { size: 'md', ariaLabelledBy: 'modal-basic-title' });
  }

  async borrarButtonClick(opcion: number) {
    if (opcion === 1) {
      await this.carritoComprasService.delete(this.carritoComprasListCurrent.id);
      this.carritoComprasList = this.carritoComprasList.filter(x => x.id !== this.carritoComprasListCurrent.id);
      await this.actializarTotal();
    }

    this.modalService.dismissAll();
  }

  async actializarTotal() {
    this.totalPedido = this.carritoComprasList.reduce(
      (partial_sum: number, item) => partial_sum + (item.cantidad * item.precioNeto), 0);
  }

  async enviarPedido() {
    this.isEnviandoPedido = true;

    const item: CarritoComprasPedidoDto = {
      items: this.carritoComprasList.map(x => ({ id: x.id, cantidad: x.cantidad })),
      observaciones: this.obsernaciones
    };

    const result = await this.carritoComprasService.enviar(item);
    if (!result) {
      this.isEnviandoPedido = false;
      this.toastr.error('Se ha producido un error reintentelo mas tarde');
      return;
    }

    await this.carritoComprasService.count();
    this.router.navigate(['/']);
  }
}
