<img class="img-fluid img-table-size" src="/Fotos/_SinFoto.jpg" *ngIf="!pictures || pictures.length == 0">
<img
  class="img-fluid img-table-size cursor-pointer"
  src="/Fotos/{{pictures[0]}}"
  onerror="this.src='/Fotos/_SinFoto.jpg';"
  *ngIf="pictures?.length == 1"
  (click)="onShowImage()">

<ng-container *ngIf="pictures?.length > 1">
    <div id="articuloFotos{{id}}{{sufijo}}" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item {{i == 0 ? 'active'  : ''}}" *ngFor="let foto of pictures; let i=index">
                <img class="img-fluid img-table-size cursor-pointer" src="/Fotos/{{foto}}" (click)="onShowImage()">
            </div>
        </div>

        <a class="carousel-control-prev" href="#articuloFotos{{id}}{{sufijo}}" role="button" data-slide="prev">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </a>
        <a class="carousel-control-next" href="#articuloFotos{{id}}{{sufijo}}" role="button" data-slide="next">
            <fa-icon [icon]="faChevronRight"></fa-icon>
        </a>
    </div>
</ng-container>
