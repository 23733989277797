import { Component, OnInit } from '@angular/core';
import { ClientesService } from '../../services/clientes.service';
import { ClientesConfigDto } from '../../Dtos/ClientesConfigDto';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login.service';


@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.css']
})
export class UserConfigComponent implements OnInit {
  faSave = faSave;
  cliente: ClientesConfigDto;

  constructor(
    private clientesService: ClientesService,
    private toastr: ToastrService,
    private loginService: LoginService) { }

  async ngOnInit() {
    await this.refreshDatos();
  }

  async refreshDatos() {
    this.cliente = (await this.clientesService.configurationGet()).response as ClientesConfigDto;
  }

  async onSave() {
    const result = await this.clientesService.configurationPost(this.cliente);
    if (!result.success) {
      this.toastr.error('Se produjo un error');
      return;
    }

    await this.refreshDatos();
    this.toastr.success('Datos actualizados');
    await this.loginService.forceRefreshToken();
  }
}
