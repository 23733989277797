import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../constantes/environment';
import { SessionState } from '../state/session.state';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ComunicationService {

  constructor(
    private http: HttpClient,
    private sessionState: SessionState,
    private router: Router) { }

  getHeader() {
    const session = this.sessionState.Get();

    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + session.token });
    return headers;
  }

  public objectToQueryString(object: any): string {
    return Object.keys(object).map(function(key) {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
                }).join('&');
  }

  public httpGet(controller: string, method: string = '', parameters: string = '', bearer: boolean = true) {
    if (method.length > 0 && method[0] !== '/') {
      method = '/' + method;
    }

    if (parameters.length > 0 && parameters[0] !== '?') {
      parameters = '?' + parameters;
    }

    let resp: any;

    if (bearer) {
      const headers = this.getHeader();
      resp = this.http.get(`${environment.apiUrl}${controller}${method}${parameters}`, { headers });
    } else {
      resp = this.http.get(`${environment.apiUrl}${controller}${method}${parameters}`);
    }

    return resp;
  }

  public httpPost(controller: string, method: string = '', body: any = null, bearer: boolean = true) {
    if (method.length > 0 && method[0] !== '/') {
      method = '/' + method;
    }

    let resp: any;

    if (bearer) {
      const headers = this.getHeader();
      resp = this.http.post(`${environment.apiUrl}${controller}${method}`, body, { headers });
    } else {
      resp = this.http.post(`${environment.apiUrl}${controller}${method}`, body);
    }

    return resp;
  }

  public httpDelete(controller: string, method: string = '', parameters: string = '', bearer: boolean = true) {
    if (method.length > 0 && method[0] !== '/') {
      method = '/' + method;
    }

    if (parameters.length > 0 && parameters[0] !== '?') {
      parameters = '?' + parameters;
    }

    let resp: any;

    if (bearer) {
      const headers = this.getHeader();
      resp = this.http.delete(`${environment.apiUrl}${controller}${method}${parameters}`, { headers });
    } else {
      resp = this.http.delete(`${environment.apiUrl}${controller}${method}${parameters}`);
    }

    return resp;
  }
}
