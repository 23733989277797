<nav aria-label="Page navigation example" *ngIf="total > 0">
    <ul class="pagination justify-content-center">
        <li class="page-item" *ngIf="showFirst">
            <button class="page-link" (click)="firstPage()">
                <fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
            </button>
        </li>

        <ng-container *ngFor="let p of pages">
            <li class="page-item" *ngIf="p != current + 1">
                <button class="page-link cursor-hand" (click)='pageChanged(p)'>{{p}}</button>
            </li>
            <li class="page-item active" aria-current="page" *ngIf="p == current + 1">
                <span class="page-link">
                  {{p}}
                  <span class="sr-only">(current)</span>
                </span>
            </li>
        </ng-container>

        <li class="page-item" *ngIf="showLast">
            <button class="page-link cursor-hand" (click)="lastPage()">
                <fa-icon [icon]="faAngleDoubleRight"></fa-icon>
            </button>
        </li>
    </ul>
</nav>