import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import { storageEnum } from 'src/app/constantes/enums/storageEnum';

import { LoginService } from 'src/app/services/login.service';
import { CarritoComprasService } from 'src/app/services/carritoCompras.service';

import { Login } from 'src/app/Dtos/Login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  errorMessage: string = undefined;
  faUser = faUser;
  faKey = faKey;

  originalValues = {
    user: '',
    password: '',
    rememberMe: true
  };


  constructor(
    private loginService: LoginService,
    private carritoComprasService: CarritoComprasService) {

    const userStr = localStorage.getItem(storageEnum.login);
    if (userStr !== null) {
      this.originalValues = JSON.parse(userStr);
    }
  }

  ngOnInit() {
  }

  async loginClick(loginForm: NgForm) {
    const login: Login = {
      user: loginForm.value.user,
      password: loginForm.value.password
    };

    const result = await this.loginService.Login(login);

    if (result.success) {
      if (loginForm.value.rememberMe) {
        localStorage.setItem(storageEnum.login, JSON.stringify(loginForm.value));
      } else if (localStorage) {
        localStorage.setItem(storageEnum.login, JSON.stringify({ user: '', password: '', rememberMe: false }));
      }

      await this.carritoComprasService.count();

      this.closebutton.nativeElement.click();
      return;
    }

    result.fields.forEach(element => {
      loginForm.form.controls[element].setErrors({Required: true});
    });

    this.errorMessage = result.message;
  }
}
