import { Injectable } from '@angular/core';
import { storageEnum } from '../constantes/enums/storageEnum';
import { Session } from '../Dtos/session';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionState {
  private isLogged = new Subject<boolean>();
  private enCarrito = new Subject<number>();

  // LOGIN -------------------------------------------------------------------------
  public async Login(session: Session) {
      localStorage.setItem(storageEnum.session, JSON.stringify(session));
      sessionStorage.setItem(storageEnum.session, JSON.stringify(session));
      this.isLogged.next(true);
  }

  public async Logout() {
    localStorage.removeItem(storageEnum.session);
    sessionStorage.setItem(storageEnum.session, JSON.stringify({ }));
    this.isLogged.next(false);
    this.EnCarrito(0);
  }

  public RefreshSession() {
    const localItem = localStorage.getItem(storageEnum.session);
    if (localItem === null || localItem === undefined) {
      return;
    }

    const session: Session = JSON.parse(localItem);
    if (session.expire < Date.now()) {
      localStorage.removeItem(storageEnum.session);
      return;
    }

    sessionStorage.setItem(storageEnum.session, localItem);
  }

  public IsLogged(): boolean {
    const sessionstr = sessionStorage.getItem(storageEnum.session);
    if (sessionstr === null) {
      return false;
    }

    const logged: Session = JSON.parse(sessionstr);
    if (logged === null || logged.token === undefined) {
      return false;
    }

    return true;
  }

  public Get(): Session {
    if (!this.IsLogged()) {
        return null;
    }

    const session: Session = JSON.parse(sessionStorage.getItem(storageEnum.session));
    return session;
  }

  public IsLogginNext(value: boolean) {
    this.isLogged.next(value);
  }

  getIsLogged(): Observable<boolean> {
    return this.isLogged.asObservable();
  }

  // CARRITO -------------------------------------------------------------------------
  public async EnCarrito(quantity: number) {
    this.enCarrito.next(quantity);
  }

  getEnCarrito(): Observable<number> {
    return this.enCarrito.asObservable();
  }
}
