import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { faSearch, faCartPlus, faCartArrowDown, faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons';

import { environment } from '../../constantes/environment';
import { stockEnum } from '../../constantes/enums/stockEnum';

import { SizeService } from 'src/app/services/size.service';
import { ToastrService } from 'ngx-toastr';
import { CarritoComprasService } from '../../services/carritoCompras.service';
import { ArticulosService } from 'src/app/services/articulos.service';

import { SessionState } from 'src/app/state/session.state';

import { ArticulosBuscar } from 'src/app/Dtos/ArticulosBuscar';
import { ArticulosVista } from 'src/app/Dtos/ArticulosVista';
import { ArticulosPreciosVista } from 'src/app/Dtos/ArticulosPreciosVista';
import { CarritoComprasAdd } from 'src/app/Dtos/CarritoComprasAdd';
import { ArticulosBusquedaResult } from '../../Dtos/ArticulosBusquedaResult';


@Component({
  selector: 'app-articulos',
  templateUrl: './articulos.component.html',
  styleUrls: ['./articulos.component.css']
})
export class ArticulosComponent implements OnInit {
  @ViewChild('pedirClosebutton') pedirClosebutton: any;

  faSearch = faSearch;
  faCartPlus = faCartPlus;
  faCartArrowDown = faCartArrowDown;
  faShoppingCart = faShoppingCart;
  faTimes = faTimes;

  articulosVistaList: ArticulosVista[];

  articulosVistaCurrent: ArticulosVista;
  articulosPreciosVistaCurrent: ArticulosPreciosVista;

  subscription: Subscription;
  totalPages = 0;
  currentPage: number;
  sufijo = 'Busq';

  lastSearch: ArticulosBuscar;

  text = '';

  constructor(
    private router: Router,
    private sessionState: SessionState,
    private articulosService: ArticulosService,
    private sizeService: SizeService,
    private modalService: NgbModal,
    private toaststr: ToastrService,
    private carritoComprasService: CarritoComprasService,
    private activatedRoute: ActivatedRoute) {

    this.subscription = this.sizeService.getIsChanged().subscribe(isChanged => {
      this.onSizeChange();
    });

    this.modalService.dismissAll();

    this.activatedRoute.queryParams
        .subscribe(params => {
          this.text = params.text;
        });
  }

  onSizeChange() {
  }

  async ngOnInit() {
    if (!this.sessionState.IsLogged()) {
      this.router.navigateByUrl('home');
    }

    this.onSizeChange();

    if (this.text === null || this.text === undefined || this.text.length == 0) {
      return;
    }

    this.lastSearch = {
      texto: this.text,
      page: 0
    };

    const result = await this.articulosService.Buscar(this.lastSearch);

    if (result.success) {
      this.mostrarBusqueda(result.response);
      return;
    }
  }

  async buscarClick(buscarForm: NgForm) {
    this.lastSearch = {
      texto: buscarForm.value.texto,
      page: 0
    };

    const result = await this.articulosService.Buscar(this.lastSearch);
    if (result.success) {
      this.mostrarBusqueda(result.response);
      return;
    }

    buscarForm.form.controls['texto'].setErrors({Required: true});
  }

  private mostrarBusqueda(articulosBusquedaResult: ArticulosBusquedaResult) {
    for (let index = 0; index < articulosBusquedaResult.articulosVista.length; index++) {
      let fotos: string[] = [];
      if (articulosBusquedaResult.articulosVista[index].foto) {
        fotos.push(articulosBusquedaResult.articulosVista[index].foto);
      }
  
      if (articulosBusquedaResult.articulosVista[index].fotos){
        articulosBusquedaResult.articulosVista[index].fotos.forEach(x => fotos.push(x));
      }     
      
      articulosBusquedaResult.articulosVista[index].fotos = fotos;
    }

    this.articulosVistaList = articulosBusquedaResult.articulosVista;

    this.totalPages = articulosBusquedaResult.totalPage;
    this.currentPage = articulosBusquedaResult.currentPage;
  }

  async onPageChanged(pageNumber: number) {
    this.lastSearch.page = pageNumber - 1;
    const result = await this.articulosService.Buscar(this.lastSearch);

    if (!result.success) {
      return;
    }

    this.mostrarBusqueda(result.response);

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  pictNotLoading(event) {
    event.target.src =  window.location.origin + environment.fotoSinfoto;
  }

  //  Verde, Amarillo, Rojo
  semaforoStock(nivel) {
    return stockEnum.classVerde;
    // if (nivel === 0) {
    //   return stockEnum.classVerde;
    // } else if (nivel === 1) {
    //   return stockEnum.classAmarillo;
    // } else {
    //   return stockEnum.classRojo;
    // }
  }

  getFotos(item: ArticulosVista): string[] {


    return item.fotos;
  }

  async showImage(modal, articuloSelect: ArticulosVista) {
    this.articulosVistaCurrent = articuloSelect;

    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title'});
  }

  async showPedir(modal, articuloSelect: ArticulosVista, precio: ArticulosPreciosVista) {
    this.articulosVistaCurrent = articuloSelect;
    this.articulosPreciosVistaCurrent = precio;

    this.modalService.open(modal, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
  }

  async carritoAgregar() {
    if (this.articulosPreciosVistaCurrent.enCarrito < this.articulosPreciosVistaCurrent.minimo) {
      this.toaststr.error('ERROR: La oferta tiene como cantidad mínima '
                                  + this.articulosPreciosVistaCurrent.minimo
                                  + ' y está intentando pedir ' + this.articulosPreciosVistaCurrent.enCarrito);
      return;
    }

    const carritoComprasAdd: CarritoComprasAdd = {
      idArticulosProcedencias: this.articulosPreciosVistaCurrent.idArticulosProcedencia,
      idOfertas: this.articulosPreciosVistaCurrent.idOferta,
      idPrecio: this.articulosPreciosVistaCurrent.idArticulosPrecio,
      cantidad: this.articulosPreciosVistaCurrent.enCarrito,
      texto: this.articulosPreciosVistaCurrent.texto
    };

    await this.carritoComprasService.add(carritoComprasAdd);

    this.modalService.dismissAll();
    return;
  }
}

