<nav class="navbar navbar-expand-lg background-default box-shadow mb-0 underline-menu">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="text-white"><fa-icon [icon]="faBars"></fa-icon></span>
    </button>

    <a class="navbar-brand" [routerLink]="['/']">
        <div class="d-none d-md-block">
            <img src="../../assets/img/logos/logo_big.png" class='img-fluid logo-grande'>
        </div>
        <div class="d-block d-md-none">
            <img src="../../assets/img/logos/logo_big.png" class='img-fluid logo-chico hidden-md-greater'>
        </div>
    </a>

    <div class="float-right d-block d-lg-none mb-0">
        <a *ngIf="isLogged && enCarrito > 0" class="cursor-pointer link-no-hover carrito-style" [routerLink]="['/carritocompras']">
            <fa-icon [icon]="faShoppingCart"></fa-icon>
        </a>

        <a *ngIf="!isLogged" class="cursor-pointer link-no-hover carrito-style ml-3" data-toggle="modal" data-target="#loginModal">
            <fa-icon [icon]="faKey"></fa-icon>
        </a>

        <div *ngIf="isLogged" class="btn-group menu-mh ml-1" role="group">
            <button id="userBtnGroupDrop" type="button" class="btn dropdown-toggle text-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <fa-icon [icon]="faUser" class="text-white"></fa-icon>
            </button>
            <div class='dropdown-menu dropdown-menu-right' aria-labelledby='userBtnGroupDrop'>
                <a class="dropdown-item cursor-pointer" [routerLink]="['/config']">Configuración</a>
                <a class="dropdown-item cursor-pointer" (click)="logoutClick()">Logout</a>
            </div>
        </div>
    </div>

    <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <ul class="navbar-nav menu-mh">
            <li class="nav-item" *ngIf="!isLogged">
                <a class="nav-link text-white" href='#quienesSomos'>Quienes somos</a>
            </li>
            <li class="nav-item" *ngIf="!isLogged">
                <a class="nav-link text-white" href='#industrias'>Industrias</a>
            </li>
            <li class="nav-item" *ngIf="!isLogged">
                <a class="nav-link text-white" href='#marcas'>Marcas</a>
            </li>
            <li class="nav-item" *ngIf="!isLogged">
                <a class="nav-link text-white" href='#productos'>Productos</a>
            </li>
            <li class="nav-item" *ngIf="!isLogged">
                <a class="nav-link text-white" href='#contactenos'>Contactenos</a>
            </li>

            <ng-container *ngIf="isLogged">
                <li class="nav-item">
                    <a class="nav-link text-white" [routerLink]="['/articulos']">Articulos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-white" [routerLink]="['/cuentascorrientes']">Cta Corriente</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-white" [routerLink]="['/config']">
                        <fa-icon [icon]="faUser" class="text-white"></fa-icon>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-white" [routerLink]="['/']" (click)="logoutClick()">
                        <fa-icon [icon]="faDoorOpen" class="text-white"></fa-icon>
                    </a>
                </li>
            </ng-container>

        </ul>

        <button *ngIf="!isLogged" type="button" class='btn menu-mh m-0 d-none d-lg-block' data-toggle="modal" data-target="#loginModal">
          <fa-icon class="text-white" [icon]="faKey"></fa-icon>
        </button>

        <!-- <div *ngIf="isLogged" class="btn-group d-none d-lg-block menu-mh ml-1" role="group">
            <button id="userBtnGroupDrop" type="button" class="btn dropdown-toggle text-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <fa-icon [icon]="faUser" class="text-white"></fa-icon>
            </button>
            <div class='dropdown-menu dropdown-menu-right' aria-labelledby='userBtnGroupDrop'>
                <a class="dropdown-item cursor-pointer" [routerLink]="['/config']">Configuración</a>
                <a class="dropdown-item cursor-pointer" (click)="logoutClick()">Logout</a>
            </div>
        </div> -->
    </div>

    <a [hidden]="isLogged" *ngIf="enCarrito > 0" class="float-right mr-5 link-no-hover d-none d-lg-block carrito-style" [routerLink]="['/carritocompras']">
        <fa-icon [icon]="faShoppingCart"></fa-icon>
    </a>
</nav>
