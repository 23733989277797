<div class="modal fade pr-0" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form class="modal-content" #loginForm="ngForm" (ngSubmit)="loginClick(loginForm)">
            <div class="modal-header">
                <h5 class="modal-title" id="loginModalLabel">Login</h5>
                <button type="button" #closebutton class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="col-12 row">
                    <div class="input-group col-12">
                        <div class="input-group-prepend">
                            <div class="input-group-text background-default text-white">
                                <fa-icon [icon]="faUser"></fa-icon>
                            </div>
                        </div>
                        <input name='user' [(ngModel)]="originalValues.user" type="text" class="form-control" placeholder="Usuario">
                    </div>

                    <div class="input-group col-12 pt-4">
                        <div class="input-group-prepend">
                            <div class="input-group-text background-default text-white">
                                <fa-icon [icon]="faKey"></fa-icon>
                            </div>
                        </div>
                        <input name='password' [(ngModel)]="originalValues.password" type="password" class="form-control" placeholder="Password">
                    </div>

                    <div class="form-check col-12 pt-4">
                        <input name='rememberMe' [(ngModel)]="originalValues.rememberMe" class="form-check-input ml-0-imp" type="checkbox" id="gridCheck1">
                        <label class="form-check-label ml-20-imp" for="gridCheck1">
                            Recuérdame
                        </label>
                    </div>

                    <div *ngIf="errorMessage !== undefined" class="col-12 pt-4">
                        <ngb-alert type="danger">{{ errorMessage }}</ngb-alert>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Login</button>
            </div>
        </form>
    </div>
</div>