import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit, OnChanges {
  @Input() current: number;
  @Input() total: number;
  @Output() pageChangeEvent = new EventEmitter<number>();

  showFirst: boolean;
  showLast: boolean;
  pages: number[];

  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;

  constructor() { }

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges) {
    this.calculate();
  }

  calculate() {
    const previousPages = 4;
    const maxPages = 9;
    const thisPage = this.current + 1;

    this.showFirst = true;
    let startIn = thisPage - previousPages;
    if (startIn < 1) {
      startIn = 1;
      this.showFirst = false;
    }

    this.showLast = true;
    let endIn = startIn + maxPages;
    if (endIn > this.total) {
      endIn = this.total;
      this.showLast = false;
    }

    this.pages = [];
    for (let index = startIn; index < endIn; index++) {
      this.pages.push(index);
    }
  }

  pageChanged(pageNumber: number) {
    this.pageChangeEvent.emit(pageNumber);
  }

  firstPage() {
    this.pageChangeEvent.emit(1);
  }

  lastPage() {
    this.pageChangeEvent.emit(this.total - 1);
  }
}
