import { Component, OnInit } from '@angular/core';
import { faCheck, faMap, faPhone, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Comunicate } from '../../Dtos/comunicate';
import { VariosService } from '../../services/varios.service';
import { NgForm } from '@angular/forms';
import { CarritoComprasService } from '../../services/carritoCompras.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  faCheck = faCheck;
  faMap = faMap;
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faPaperPlane = faPaperPlane;
  faWhatsapp = faWhatsapp;
  faFacebook = faFacebook;
  faInstagram = faInstagram;

  productosText: string[] = [
    'Rodamientos a bolas radiales',
    'Axiales',
    'Con contacto angular',
    'Blindadas',
    'Simples y dobles',
    'Rodillos conicos',
    'Rodillos cilindricos',
    'Rodillos esfericos',
    'Tensores',
    'Correas',
    'Bombas de agua',
    'Kit de distribucion',
    'Homocineticas lado caja',
    'Homocineticas lado rueda',
    'Maza de rueda',
    'Kit de embrague placa/disco',
    'Rotulas',
    'Punta de eje',
    'Crapodinas mecanicas',
    'Crapodinas hidraulicas',
    'Grasas',
    'Retenes de rueda',
    'Retenes de distribucion',
    'Retenes de arbol de leva',
    'Retenes de caja de cambio',
    'Pastillas de frenos',
    'Discos de freno',
    'Campanas de freno',
    'Bloques de freno',
    'Cintas livianas',
    'Cintas pesadas',
    'Remaches',
    'Crucetas',
    'Tricetas',
    'Bolillas',
    'Soporte cardan',
  ];

  constructor(
    private variosService: VariosService,
    private toastr: ToastrService,
    private carritoComprasService: CarritoComprasService) {
  }

  async ngOnInit() {
    await this.carritoComprasService.count();
  }

  async comunicateClick(comunicateForm: NgForm) {
    const envio: Comunicate = {
      name: comunicateForm.value.name,
      email: comunicateForm.value.email,
      message: comunicateForm.value.message
    };

    const result = await this.variosService.Comunicate(envio);

    if (result.success) {
      comunicateForm.reset();
      this.toastr.success('El e-mail se envio correctamente');

      return;
    }

    result.fields.forEach(element => {
      comunicateForm.form.controls[element].setErrors({Required: true});
    });
  }
}
