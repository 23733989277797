import { Injectable } from '@angular/core';
import { ComunicationService } from './comunication.service';
import { LoginService } from './login.service';
import { PagedList } from '../Dtos/PagedList';
import { HttpErrorResponse } from '@angular/common/http';
import { CuentasCorrientesView } from '../Dtos/CuentasCorrientesView';
import { CuentasCorrientesCurrent } from '../Dtos/CuentasCorrientesCurrent';

@Injectable({
  providedIn: 'root'
})
export class CuentasCorrientesService {

  constructor(
    private comunicationService: ComunicationService,
    private loginService: LoginService) { }

  public async Current(page: number): Promise<CuentasCorrientesCurrent> {
    let ret: CuentasCorrientesCurrent;

    try {
      await this.loginService.ifNecessaryRefreshToken();
      const resp = await this.comunicationService.httpGet('CuentasCorrientes', 'current', 'page=' + page)
                                                  .toPromise();
      ret = resp;
    } catch (error) {
      ret = null;
      if (error instanceof HttpErrorResponse) {
      }
    }

    return ret;
  }

  public async Historic(page: number): Promise<PagedList> {
    let ret: PagedList;

    try {
      await this.loginService.ifNecessaryRefreshToken();
      const resp = await this.comunicationService.httpGet('CuentasCorrientes', 'historic', 'page=' + page)
                                                  .toPromise();
      ret = resp;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
      }
    }

    return ret;
  }

  public async GetById(id: number): Promise<CuentasCorrientesView> {
    let ret: CuentasCorrientesView;

    try {
      await this.loginService.ifNecessaryRefreshToken();
      ret = await this.comunicationService.httpGet('CuentasCorrientes/' + id)
                                                  .toPromise();
    } catch (error) {
    }

    return ret;
  }
}
