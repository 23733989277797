import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pictures-show',
  templateUrl: './pictures-show.component.html',
  styleUrls: ['./pictures-show.component.css']
})
export class PicturesShowComponent implements OnInit, OnChanges {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  @Input() id: number = 0;
  @Input() sufijo = '';
  @Input() pictures: string[] = [];
  @Input() modalTitle: string[] = [];
  @Output() showImage = new EventEmitter();

  constructor(  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  async onShowImage() {
    this.showImage.emit();
  }
}
