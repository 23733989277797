import { Injectable } from '@angular/core';
import { ComunicationService } from './comunication.service';
import { Result } from '../Dtos/Result';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from './login.service';
import { SessionState } from '../state/session.state';
import { ClientesConfigDto } from '../Dtos/ClientesConfigDto';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(
    private comunicationService: ComunicationService,
    private sessionState: SessionState,
    private loginService: LoginService) { }

  public async configurationGet(): Promise<Result> {
    const ret: Result = { success: true, fields: []  };

    try {
      await this.loginService.ifNecessaryRefreshToken();

      const resp = await this.comunicationService.httpGet('Clientes', 'Configuration').toPromise();
      ret.response = resp;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        ret.success = false;
      }
    }

    return ret;
  }

  public async configurationPost(clientesConfigDto: ClientesConfigDto): Promise<Result> {
    const ret: Result = { success: true, fields: []  };

    try {
      await this.loginService.ifNecessaryRefreshToken();

      const resp = await this.comunicationService.httpPost('Clientes', 'Configuration', clientesConfigDto).toPromise();
      ret.response = resp;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        ret.success = false;
      }
    }

    return ret;
  }
}
