import { Component, OnInit } from '@angular/core';
import { CuentasCorrientesService } from 'src/app/services/cuentas-corrientes.service';
import { PagedList } from 'src/app/Dtos/PagedList';
import { CuentasCorrientesView } from 'src/app/Dtos/CuentasCorrientesView';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CuentasCorrientesCurrent } from 'src/app/Dtos/CuentasCorrientesCurrent';


@Component({
  selector: 'app-cuentas-corrientes',
  templateUrl: './cuentas-corrientes.component.html',
  styleUrls: ['./cuentas-corrientes.component.css']
})
export class CuentasCorrientesComponent implements OnInit {

  faTimes = faTimes;

  current: CuentasCorrientesCurrent;
  historic: PagedList;

  document: CuentasCorrientesView;

  constructor(
    private cuentasCorrientesService: CuentasCorrientesService,
    private modalService: NgbModal) { }

  async ngOnInit() {
    await this.onCurrentPageChanged(1);
    await this.onHistoricPageChanged(1);
  }

  async onCurrentPageChanged(pageNumber: number) {
    const result = await this.cuentasCorrientesService.Current(pageNumber - 1);

    if (result == null) {
      return;
    }

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.current = result;
  }

  async onHistoricPageChanged(pageNumber: number) {
    const result = await this.cuentasCorrientesService.Historic(pageNumber - 1);

    if (result == null) {
      return;
    }

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.historic = result;
  }

  async showDocument(modal, id: number) {
    this.document = await this.cuentasCorrientesService.GetById(id);
    this.modalService.open(modal, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }
}



