<section class='container-fluid'>
    <form #buscarForm="ngForm" (ngSubmit)="buscarClick(buscarForm)">
        <div class='row mt-4'>

            <div class="col-10">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text background-default text-white">
                            C&oacute;digo / Ubicaci&oacute;n t
                        </div>
                    </div>
                    <input name='texto' [(ngModel)]="text" type="text" class="form-control" placeholder="C&oacute;digo del Articulo y/o Ubicaci&oacute;n. Ej.: VKM 1258 / Maza Ford Fiesta / VKM FIAT">
                </div>
            </div>

            <div class='col-2'>
                <div class="d-none d-md-block w-100">
                    <button class="btn btn-dark w-100" type="submit"><fa-icon [icon]="faSearch"></fa-icon> Buscar</button>
                </div>

                <div class="d-block d-md-none w-100">
                    <button class="btn btn-dark w-100" type="submit"><fa-icon [icon]="faSearch"></fa-icon></button>
                </div>
            </div>
        </div>
    </form>
</section>


<section class='container-fluid'>
    <div class="row py-3">
        <div class='col-12 col-md-6 col-lg-4 col-xl-3 mb-3' *ngFor="let articulosVista of articulosVistaList">
            <div class="card w-100">
                <div class="card-header background-default background-default-color text-center">
                    {{articulosVista.codigo}}
                </div>

                <h6 class='text-center' *ngIf="articulosVista.equivalencia">{{articulosVista.equivalencia}}</h6>
                <app-pictures-show
                  [id]="articulosVista.idArticulos"
                  [sufijo]="sufijo"
                  [pictures]="articulosVista.fotos"
                  [modalTitle]="articulosVista.codigo"
                  (showImage)="showImage(imageModal, articulosVista)">
                </app-pictures-show>

                <div class="card-body">
                    <p *ngIf="articulosVista.medidas" class="card-text">{{articulosVista.medidas}}</p>
                    <p class="card-text">{{articulosVista.notas}}</p>
                </div>

                <div class="card-footer p-0">
                    <table class="w-100">
                        <ng-container *ngFor="let precio of articulosVista.precios">
                            <ng-container *ngIf="precio.texto">
                                <tr>
                                    <td rowspan="2" class='text-left pl-1 {{semaforoStock(precio.semaforoStock)}}'>
                                        {{precio.procedencia}}
                                        <strong *ngIf="precio.enCarrito > 0" class='color-default'>({{precio.enCarrito}})</strong>
                                    </td>
                                    <td class='text-right {{semaforoStock(precio.semaforoStock)}}'>
                                        <strong>$ {{precio.costo | number: '.2-2'}}.-</strong>
                                    </td>
                                    <td rowspan="2" class='text-right pr-1 pedir-column-width {{semaforoStock(precio.semaforoStock)}}'>
                                        <button type="button" class="btn btnNoFocus" (click)="showPedir(pedirModal, articulosVista, precio)" focusable='false'>
                                          <fa-icon [icon]="faCartPlus" class='color-default'></fa-icon>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='text-right px-1 {{semaforoStock(precio.semaforoStock)}}'>
                                        <p class="mb-0 text-danger">$ {{precio.venta | number: '.2-2'}}.-</p>
                                    </td>
                                </tr>
                                <tr class='border-boton'>
                                    <td class='text-left px-1 {{semaforoStock(precio.semaforoStock)}}' colspan="3">
                                        {{precio.texto}}
                                    </td>
                                </tr>
                            </ng-container>


                            <ng-container *ngIf="!precio.texto">
                                <tr>
                                    <td rowspan="2" class='text-left pl-1 {{semaforoStock(precio.semaforoStock)}}'>
                                        {{precio.procedencia}}
                                        <strong *ngIf="precio.enCarrito > 0" class='color-default'>({{precio.enCarrito}})</strong>
                                    </td>
                                    <td class='text-right {{semaforoStock(precio.semaforoStock)}}'>
                                        <strong>$ {{precio.costo | number: '.2-2'}}.-</strong>
                                    </td>
                                    <td rowspan="2" class='text-right pedir-column-width pr-1 {{semaforoStock(precio.semaforoStock)}}'>
                                        <button type="button" class="btn ph-5 btnNoFocus" (click)="showPedir(pedirModal, articulosVista, precio)" focusable='false'>
                                            <fa-icon [icon]="faCartPlus" class='color-default'></fa-icon>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='text-right px-1 {{semaforoStock(precio.semaforoStock)}}'>
                                        <p class="mb-0 text-danger">$ {{precio.venta | number: '.2-2'}}.-</p>
                                    </td>
                                </tr>
                                <tr class='border-boton'>
                                    <td class='{{semaforoStock(precio.semaforoStock)}} height-1 px-1' colspan="3">
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="col-12 pt-5">
        <div class="col-12">
            <app-pages [total]='totalPages' [current]='currentPage' (pageChangeEvent)="onPageChanged($event)"></app-pages>
        </div>
    </div>

</section>

<ng-template #imageModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="loginModalLabel">{{articulosVistaCurrent.codigo}}</h5>
        <button type="button" class="btn btn-transparent btnNoFocus" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><fa-icon [icon]="faTimes"></fa-icon></span>
        </button>
    </div>

    <div class="modal-body">
        <app-pictures-show
          [id]="articulosVistaCurrent.idArticulos"
          [pictures]="articulosVistaCurrent.fotos"
          [modalTitle]="articulosVistaCurrent.codigo">
        </app-pictures-show>
    </div>
</ng-template>


<ng-template #pedirModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="loginModalLabel">{{articulosVistaCurrent.codigo}}</h5>
        <button type="button" #pedirClosebutton class="btn btn-transparent btnNoFocus" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><fa-icon [icon]="faTimes"></fa-icon></span>
        </button>
    </div>

    <div class="modal-body">
        <div class='row'>
            <div class="col-12 col-lg-6">
                <div class="d-none d-lg-block">
                    <!-- <img class="card-img-top" [src]="articulosVistaCurrent.fotos"> -->
                    <app-pictures-show
                      [id]="articulosVistaCurrent.idArticulos"
                      [pictures]="articulosVistaCurrent.fotos"
                      [modalTitle]="articulosVistaCurrent.codigo">
                    </app-pictures-show>
                </div>

            </div>

            <div class='col-12 col-lg-6'>
                <table class="w-100">
                    <tr>
                        <td colspan="2">
                            <p *ngIf="articulosVistaCurrent.medidas" class="card-text">{{articulosVistaCurrent.medidas}}</p>
                            <p class="card-text">{{articulosVistaCurrent.notas}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td class='py-3'>
                            Marca
                        </td>
                        <td class='py-3'>
                            <strong> {{articulosPreciosVistaCurrent.procedencia}}</strong>
                        </td>
                    </tr>
                    <tr class='py-3'>
                        <td class='py-3'>
                            P.U
                        </td>
                        <td class='text-right py-3'>
                            <strong> $ {{articulosPreciosVistaCurrent.costo}}.-</strong>
                        </td>
                    </tr>
                    <tr class='py-3'>
                        <td class='py-3'>
                            Cantidad
                        </td>
                        <td class='text-rightpy-3'>
                            <input [(ngModel)]="articulosPreciosVistaCurrent.enCarrito" type="number" class="form-control col-12 col-md-6 offset-md-6 text-right">
                        </td>
                    </tr>
                    <tr *ngIf="articulosPreciosVistaCurrent.texto" class='py-3'>
                        <td class='py-3' colspan="2">
                            {{articulosPreciosVistaCurrent.texto}}
                        </td>
                    </tr>
                    <tr>
                        <td class='py-4'>
                            SubTotal
                        </td>
                        <td class='text-right py-3'>
                            <strong> $ {{articulosPreciosVistaCurrent.costo * articulosPreciosVistaCurrent.enCarrito | number: '.2-2'}}.-</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class='py-4 text-center' colspan="2">
                            <button class="btn background-default background-default-color" type="button" (click)="carritoAgregar()">
                                <fa-icon [icon]="faCartPlus"></fa-icon> Agregar a carrito
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</ng-template>



<section class='container-fluid pv-20'>
    &nbsp;
</section>
