import { Component, OnInit } from '@angular/core';
import { faBars, faKey, faUser, faShoppingCart, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SessionState } from '../../state/session.state';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  faBars = faBars;
  faKey = faKey;
  faUser = faUser;
  faDoorOpen = faDoorOpen;
  faShoppingCart = faShoppingCart;

  isLogged = false;
  loginSubscription: Subscription;

  carritoSubscription: Subscription;
  enCarrito = 0;

  constructor(
    private sessionState: SessionState,
    private router: Router) {

    // subscribe to home component messages
    this.loginSubscription = this.sessionState.getIsLogged().subscribe(isLogged => {
      this.isLogged = isLogged;
    });

    this.carritoSubscription = this.sessionState.getEnCarrito().subscribe(enCarrito => {
      this.enCarrito = enCarrito;
    });
  }

  ngOnInit() {
    this.isLogged = this.sessionState.IsLogged();
  }

  async logoutClick() {
    await this.sessionState.Logout();
    this.router.navigateByUrl('home');
  }
}
