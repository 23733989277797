<section class='container'>
    <div class="py-5">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="actual-tab" data-toggle="tab" href="#actual" role="tab" aria-controls="actual" aria-selected="true">Actual</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="historica-tab" data-toggle="tab" href="#historica" role="tab" aria-controls="historica" aria-selected="false">Histórica</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="actual" role="tabpanel" aria-labelledby="actual-tab" *ngIf="current">
                <div class="d-none d-md-block">
                    <table class="table table-hover table-colored table-borderless">
                        <thead>
                            <th class='text-center align-middle'>Fecha</th>
                            <th class='text-center align-middle'>Documento</th>
                            <th class='text-center align-middle'>Total</th>
                            <th class='text-center align-middle'>Saldo</th>
                        </thead>

                        <tbody>
                            <tr class="bb-colored" *ngFor="let item of current.items">
                                <td>{{item.fecha | date:'dd/MM/yyyy'}}</td>
                                <td>
                                    <button type="button" class="btn ph-5 btnNoFocus" focusable='false' (click)="showDocument(documentModal, item.id)">
                                  {{item.titulo}}
                              </button>
                                </td>
                                <td class='text-right'>$ {{item.total | number: '.2-2'}}.-</td>
                                <td class='text-right'>$ {{item.saldo | number: '.2-2'}}.-</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td class='text-right background-default background-default-color'>
                                    <h5>Total</h5>
                                </td>
                                <td class='text-right background-default background-default-color'>
                                    <h5>$ {{current.deudaTotal | number: '.2-2'}}.-</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-block d-md-none">
                    <table class="table table-hover table-colored table-borderless">
                        <thead>
                            <th class='text-center align-middle'>Documento</th>
                            <th class='text-center align-middle'>Saldo</th>
                        </thead>

                        <tbody>
                            <tr class="bb-colored" *ngFor="let item of current.items">
                                <td>
                                    <button type="button" class="btn ph-5 btnNoFocus" focusable='false' (click)="showDocument(documentModal, item.id)">
                                        {{item.titulo}}
                                    </button>
                                </td>
                                <td class='text-right'>$ {{item.saldo | number: '.2-2'}}.-</td>
                            </tr>
                            <tr>
                                <td class='text-right background-default background-default-color' colspan="2">
                                    <h5>Total $ {{current.deudaTotal | number: '.2-2'}}.-</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12">
                    <app-pages [total]='current.totalPage' [current]='current.currentPage' (pageChangeEvent)='onCurrentPageChanged($event)'></app-pages>
                </div>
            </div>
            <div class="tab-pane fade" id="historica" role="tabpanel" aria-labelledby="historica-tab" *ngIf="historic">
                <table class="table table-hover table-striped table-colored table-borderless">
                    <thead>
                        <th class='text-center align-middle'>Fecha</th>
                        <th class='text-center align-middle'>Documento</th>
                        <th class='text-center align-middle'>Total</th>
                    </thead>

                    <tbody>
                        <tr class="bb-colored" *ngFor="let item of historic.items">
                            <td>{{item.fecha | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <button type="button" class="btn ph-5 btnNoFocus" focusable='false' (click)="showDocument(documentModal, item.id)">
                                {{item.titulo}}
                            </button>
                            </td>
                            <td class='text-right'>$ {{item.total | number: '.2-2'}}.-</td>
                        </tr>
                    </tbody>
                </table>

                <div class="col-12">
                    <app-pages [total]='historic.totalPage' [current]='historic.currentPage' (pageChangeEvent)='onHistoricPageChanged($event)'></app-pages>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #documentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="loginModalLabel">{{document.titulo}}</h5>
        <button type="button" #pedirClosebutton class="btn btn-transparent btnNoFocus" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><fa-icon [icon]="faTimes"></fa-icon></span>
        </button>
    </div>

    <div class="modal-body">
        <div class='row'>

            <div class="col-12 text-center">
                <p class='mb-0'><strong class='color-default'><h5><u>Fecha</u></h5></strong> {{document.fecha | date:'dd/MM/yyyy'}}</p>
            </div>

            <div class="col-12">
                <div class="d-none d-lg-block">
                    <table class="table table-hover table-colored table-borderless">
                        <thead>
                            <th class='text-center'>Codigo</th>
                            <th class='text-center'>Marca</th>
                            <th class='text-center'>Cantidad</th>
                            <th class='text-center'>P.U</th>
                            <th class='text-center'>Sub Total</th>
                        </thead>
                        <tbody>
                            <tr class="bb-colored" *ngFor="let item of document.lineas">
                                <td>
                                    <a [routerLink]="['/articulos']" [queryParams]='{ text:item.codigo}'>
                                    {{item.codigo}}
                                </a>
                                </td>
                                <td>
                                    {{item.procedencia}}
                                </td>
                                <td class='text-right'>
                                    {{item.cantidad}}
                                </td>
                                <td class='text-right'>
                                    {{item.moneda}} {{item.precioUnitario | number: '.2-2'}}.-
                                </td>
                                <td class='text-right'>
                                    {{item.moneda}} {{item.subTotal | number: '.2-2'}}.-
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="d-none d-sm-block d-lg-none">
                    <table class="table table-hover table-colored table-borderless">
                        <thead>
                            <th class='text-center'>Codigo</th>
                            <th class='text-center'>Cantidad</th>
                            <th class='text-center'>P.U</th>
                            <th class='text-center'>Sub Total</th>
                        </thead>
                        <tbody>
                            <tr class="bb-colored" *ngFor="let item of document.lineas">
                                <td>
                                    <a [routerLink]="['/articulos']" [queryParams]='{ text:item.codigo}'>
                                    {{item.codigo}} {{item.procedencia}}
                                  </a>
                                </td>
                                <td class='text-right'>
                                    {{item.cantidad}}
                                </td>
                                <td class='text-right'>
                                    {{item.moneda}} {{item.precioUnitario | number: '.2-2'}}.-
                                </td>
                                <td class='text-right'>
                                    {{item.moneda}} {{item.subTotal | number: '.2-2'}}.-
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="d-block d-sm-none">
                    <table class="table table-hover table-colored table-borderless">
                        <tbody>
                            <tr class="bb-colored" *ngFor="let item of document.lineas">
                                <td>
                                    <a [routerLink]="['/articulos']" [queryParams]='{ text:item.codigo}'>
                                  {{item.codigo}} {{item.procedencia}}
                                </a>
                                </td>
                                <td class='text-right'>
                                    {{item.cantidad}} = {{item.moneda}} {{item.subTotal | number: '.2-2'}}.-
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-12 col-md-5 offset-md-7">
                <table class="table table-colored table-borderless">
                    <tr class="bb-colored bt-colored br-colored">
                        <td class='background-default background-default-color'>
                            Neto
                        </td>
                        <td class='text-right'>
                            $ {{document.neto | number: '.2-2'}}.-
                        </td>
                    </tr>
                    <tr class="bb-colored br-colored">
                        <td class='background-default background-default-color'>
                            IVA
                        </td>
                        <td class='text-right'>
                            $ {{document.iva | number: '.2-2'}}.-
                        </td>
                    </tr>
                    <tr class="bb-colored br-colored">
                        <td class='background-default background-default-color'>
                            Total
                        </td>
                        <td class='text-right'>
                            $ {{document.total | number: '.2-2'}}.-
                        </td>
                    </tr>
                    <tr class="bb-colored br-colored" *ngIf='document.saldo'>
                        <td class='background-default background-default-color'>
                            Saldo
                        </td>
                        <td class='text-right'>
                            $ {{document.saldo | number: '.2-2'}}.-
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</ng-template>